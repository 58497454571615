<template>
  <div class="submit_article_page">
    <back-btn
      class="back_btn"
      :handle-click="
        () => ($route.query.id ? $router.back() : $router.push('/writing'))
      "
    ></back-btn>
    <div v-if="$route.query.id" style="float: right">
      当前剩余{{ $route.query.count }}次
    </div>
    <div class="submitArticle">
      <div class="title">选择作文类型</div>
      <my-tabs
        :type="$route.query.id ? `practice` : ''"
        :tabs="tabs"
        v-model:active-tab="form.writingType"
      />
      <div class="line"></div>
      <div
        class="myFormItem"
        v-if="editInfo.teacherBackReason && editInfo.status === 2"
      >
        <div class="form_label">
          <div class="label_width">老师打回时间：</div>
          <div class="form_content">{{ editInfo.teacherBackDate }}</div>
        </div>
        <div class="form_label">
          <div class="label_width">老师打回原因：</div>
          <div class="form_content" v-html="editInfo.teacherBackReason"></div>
        </div>
      </div>
      <div
        class="line"
        v-if="editInfo.teacherBackReason && editInfo.status === 2"
      ></div>
      <div class="myFormItem">
        <div class="form_label">请输入作文题目</div>
        <el-input
          :disabled="isEdit"
          autosize
          type="textarea"
          v-model="form.subjectContent"
        />
        <div v-if="!$route.query.id">
          <div class="wordNum" v-if="titleNum <= 200">
            <!-- 已输入{{ titleNum }}词，还可输入{{ 200 - titleNum }}词 -->
            <span class="normalNum">{{ titleNum }}</span
            >/200
          </div>
          <div class="wordNum" v-else>
            已输入{{ titleNum }}词，已超出<span class="overNum">{{
              titleNum - 200
            }}</span
            >词
          </div>
        </div>
        <upload-file
          @onSuccess="(val) => (form.subjectUrl = val)"
          :imgUrl="form.subjectUrl"
          folder-code="39"
          :limit="5"
          :type="$route.query.id ? 'practiceSubmit' : ''"
        />
      </div>
      <div class="myFormItem">
        <div class="form_label">请输入作文内容</div>
        <el-input
          :autosize="{ minRows: 12, maxRows: 12 }"
          type="textarea"
          :placeholder="`请输入50-${limitInputNum}词`"
          v-model="form.writingContent"
        />
        <div v-if="wordNum === 0" class="wordNum">
          <!-- 已输入0词，字数范围50-{{ limitInputNum }}词 -->
          0/{{limitInputNum}}
        </div>
        <div v-else-if="wordNum < 50 && wordNum > 0" class="wordNum">
            *作文内容不能少于50词&nbsp;&nbsp;&nbsp;&nbsp;<span>{{ wordNum }}</span>/600
        </div>
        <div v-else-if="wordNum > 0" class="wordNum">
          <!-- 已输入<span>{{ wordNum }}</span>词 -->
          <span :class="wordNum > 600 ? '' : 'normalNum'">{{ wordNum }}</span>/600
        </div>
      </div>
      <div
        class="myFormItem"
        v-if="isUpdateMode ? editInfo.ifExperience : isWritingExperience"
      >
        <div class="form_label">英文名</div>
        <el-input
          v-model="formExtra.nickName"
          placeholder="请填写英文名"
          class="smallInput"
          show-word-limit
          :maxlength="20"
        />
      </div>
      <div
        class="myFormItem"
        v-if="isUpdateMode ? editInfo.ifExperience : isWritingExperience"
      >
        <div class="form_label">请选择目标分数</div>
        <el-select v-model="formExtra.targetWriteGrade" placeholder="请选择">
          <el-option
            v-for="item in scoreOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="submit_btn">
        <div
          v-if="wordNum > limitFreeNum && wordNum <= limitInputNum"
          class="overNum"
        >
          当前超出词数：<span>{{ wordNum - limitFreeNum }}</span>
          词，您还需支付超字数费用才能提交作文
        </div>
        <div v-if="wordNum > limitInputNum" class="overNum">
          作文内容限制{{ limitInputNum }}词， 当前超出词数：<span>{{
            wordNum - limitInputNum
          }}</span
          >词
        </div>
        <div>
          <el-button
            v-if="writingStatus != 2"
            @click="saveWriting"
            plain
            type="primary"
            >保存草稿</el-button
          >
          <el-button
            :disabled="btnDisabled"
            @click="submitWrite"
            type="primary"
          >
            提交作文
          </el-button>
        </div>
      </div>
      <pay-addword
        :over-num="wordNum - limitFreeNum"
        :price="submitRes.addWordsAmount"
        :order-code="submitRes.orderCode"
        ref="payAdd"
      />
      <el-dialog
        v-model="dialogVisible"
        title="操作提示"
        width="30%"
        :before-close="handleClose"
      >
        <span>您的批改剩余次数为0，请先去首页购买写作精批权益</span>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="dialogVisible = false"
              >确定</el-button
            >
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import http from "@/utils/request";
import { getNumber } from "@/utils/common";
import { connectState } from "@/utils/wyUtil";
import MyTabs from "@/components/MyTabs.vue";
import UploadFile from "./components/UploadFile.vue";
import PayAddword from "./components/PayAddword.vue";
import BackBtn from "../../components/BackBtn.vue";

export default {
  components: {
    MyTabs,
    UploadFile,
    PayAddword,
    BackBtn,
  },
  data() {
    return {
      tabs: [
        {
          label: "大作文",
          value: 0,
        },
        {
          label: "小作文",
          value: 1,
        },
      ],
      form: {
        writingType: "",
        subjectContent: "",
        writingContent: "",
        subjectUrl: "",
      },
      formExtra: {
        nickName: "",
        targetWriteGrade: 0,
      },
      submitRes: {},
      writingStatus: "",
      scoreOptions: new Array(11).fill(4).map((item, index) => ({
        label: item + index * 0.5,
        value: item + index * 0.5,
      })),
      isUpdateMode: false,
      dialogVisible: false,
      isEdit: false,
      cancalTextBtn: true // 提交作文弹窗取消按钮是否展示
    };
  },
  computed: {
    ...connectState("writing", [
      "editInfo",
      "isWritingExperience",
      "writingGoalScore",
      "userWritingName",
    ]),
    wordNum() {
      return getNumber(this.form.writingContent);
    },
    titleNum() {
      return getNumber(this.form.subjectContent);
    },
    limitInputNum() {
      return this.form.writingType ? 550 : 600;
    },
    limitFreeNum() {
      return this.form.writingType ? 250 : 350;
    },
    btnDisabled() {
      return (
        // (!this.form.subjectUrl && !this.form.subjectContent) ||
        !this.form.writingContent ||
        this.wordNum < 50
      );
    },
  },
  watch: {
    editInfo(val) {
      const {
        subjectContent,
        writingContent,
        writingType,
        subjectUrl,
        status,
        nickName,
        targetWriteGrade,
      } = val || {};
      this.writingStatus = status;
      this.form.subjectContent = subjectContent;
      this.form.writingContent = writingContent;
      // this.form.writingType = writingType;
      this.form.subjectUrl = subjectUrl;
      if (this.isUpdateMode) {
        this.formExtra.nickName = nickName;
        this.formExtra.targetWriteGrade = targetWriteGrade;
      }
    },
    writingGoalScore: {
      handler: function (val) {
        this.formExtra.targetWriteGrade = val;
      },
      immediate: true,
    },
    userWritingName: {
      handler: function (val) {
        this.formExtra.nickName = val;
      },
      immediate: true,
    },
  },
  async created() {
    if (this.$route.query.correctingId) {
      // 查看详情
      this.isUpdateMode = true;
      this.$store.dispatch(
        "writing/getWriteInfo",
        this.$route.query.correctingId
      );
    } else if (this.$route.query.id) {
      this.getInterimWriting();
      const res = await http(
        `/hcp/yida/studyPlan/getQuestionsByDetailId/${this.$route.query.pid}`,
        {
          method: "GET",
        }
      );
      for (let i = 0; i < res.length; i++) {
        if (res[i].writingId === this.$route.query.id) {
          this.form.writingType = res[i].writingPart;
          this.form.subjectContent = res[i].title;
          this.form.subjectUrl = res[i].imageUrl.join(",");
        }
      }
      this.isEdit = true;
    } else {
      // 提交作文
      this.isUpdateMode = false;
      this.getInterimWriting();
      this.$store.dispatch("writing/submitNum");
    }
  },
  methods: {
    saveWriting() {
      this.$store.dispatch("writing/saveInterimWriting", {
        ...this.form,
        callback: () => {
          this.$message({ message: "保存草稿成功", type: "success" });
          this.$router.push("/writing");
        },
      });
    },
    getInterimWriting() {
      this.$store.dispatch("writing/getInterimWriting");
    },
    submitWrite() {
      if (!this.form.writingType && this.form.writingType !== 0) {
        this.$message.error("请选择作文类型");
        return;
      }
      if (this.titleNum > 200) {
        this.$message.error("标题字数请控制在200词以内");
        return;
      }
      if (this.wordNum > this.limitInputNum) {
        this.$message.error(`作文内容字数请控制在${this.limitInputNum}词以内`);
        return;
      }
      if (!this.form.subjectContent&&!this.form.subjectUrl) {
        this.$message.error(`作文题目/图片不能为空`);
        return;
      }
      let isExp = this.isUpdateMode
        ? this.editInfo.ifExperience
        : this.isWritingExperience;
      if (isExp) {
        const { targetWriteGrade, nickName } = this.formExtra || {};
        if (!nickName) {
          this.$message.error("请填写英文名");
          return;
        }
        if (!targetWriteGrade) {
          this.$message.error("请设置目标分数");
          return;
        }
        this.form = { ...this.form, ...this.formExtra };
      }
      if (this.$route.query.count == 0) {
        if (this.editInfo.status !== 2) {
          this.dialogVisible = true;
          return false;
        }
      }
      
      let text = '确定提交作文吗?';
      let confirmText = '确定';

      if (this.wordNum > this.limitFreeNum && this.wordNum <= this.limitInputNum) {
        text = '大作文超过350词，小作文超过250词，将会收取额外费用';
        confirmText = '我知道了';
        this.cancalTextBtn = false
      } else {
        this.cancalTextBtn = true
      }
      this.$confirm(text, "提示", {
        confirmButtonText: confirmText,
        cancelButtonText: '取消',
        showCancelButton: this.cancalTextBtn,
        type: "warning",
      }).then(async () => {
        const ifAddWords = this.wordNum <= this.limitFreeNum ? 0 : 1;
        let res = {};
        if (this.$route.query.correctingId) {
          res =
            (await http("/hcp/yida/writingCorrecting/userModifyWrite", {
              method: "POST",
              data: {
                ...this.form,
                ifAddWords,
                correctingId: this.$route.query.correctingId,
              },
            })) || {};
        } else {
          console.log({ ...this.form, ifAddWords });
          let data = null;
          if (this.$route.query.id) {
            data = {
              ...this.form,
              ifAddWords,
              planDetailId: this.$route.query.pid,
              writingId: this.$route.query.id,
            };
          } else {
            data = { ...this.form, ifAddWords };
          }
          res =
            (await http("/hcp/yida/writingCorrecting/submitWrite", {
              method: "POST",
              data: data,
            })) || {};
        }
        this.submitRes = res;
        if (!res.orderCode) {
          this.$message.success("作文提交成功！");
          setTimeout(() => {
            this.$router.push("/writing");
          }, 200);
        } else {
          this.$refs.payAdd.init();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.submit_article_page {
  width: 1200px;
  margin: 45px auto 0;
  .back_btn {
    margin-bottom: 20px;
  }
}
.submitArticle {
  background-color: #fff;
  padding: 45px 40px 20px;
  box-sizing: border-box;

  .title {
    color: #131b33;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .line {
    height: 5px;
    width: 1200px;
    background-color: #f8f8f8;
    margin: 30px 0 20px -40px;
  }
  .myFormItem {
    &::v-deep .el-textarea__inner {
      color: #131b33 !important;
    }
    margin-bottom: 20px;
    .form_content {
      font-size: 16px;
      font-weight: normal;
      line-height: 30px;
      word-break: break-all;
    }
    .form_label {
      display: flex;
      color: #131b33;
      font-size: 22px;
      font-weight: bold;
      position: relative;
      padding-left: 14px;
      margin-bottom: 15px;
      .label_width {
        min-width: 160px;
      }
      &::before {
        content: "";
        width: 4px;
        height: 20px;
        background-color: #3377ff;
        border-radius: 0 44px 44px 0;
        position: absolute;
        top: 5px;
        left: 0;
      }
    }
    .smallInput {
      width: 193px;
    }
    .wordNum {
      color: #72777e;
      text-align: right;
      margin-top: 10px;

      .normalNum {
        color: #3377ff;
      }
      span {
        color: #ff2342;
      }
    }
  }
  .submit_btn {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .overNum {
      color: #72777e;
      margin-right: 20px;
      span {
        color: #ff2342;
      }
    }
  }
}
</style>
