<template>
  <div class="payAddword">
    <el-dialog title="作文加字付费" v-model="visible" width="400px" center>
      <el-form :model="form">
        <el-form-item label="购买词数：">
          <span class="blue">{{ overNum }}</span> 词
        </el-form-item>
        <el-form-item label="支付方式：">
          <div class="pay" @click="payType = 2" :class="{ active: payType === 2 }">
            <img src="../../../assets/img/wx_pay.png" alt="" />
            微信支付
          </div>
          <div class="pay" @click="payType = 1" :class="{ active: payType === 1 }">
            <img src="../../../assets/img/ali_pay.png" alt="" />
            支付宝支付
          </div>
        </el-form-item>
        <el-form-item label="应付金额：">
          <span class="red">￥{{ price }}</span>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" @click="toPay">去支付</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import http from "@/utils/request";

export default {
  props: {
    overNum: Number,
    price: Number,
    orderCode: String
  },
  data() {
    return {
      visible: false,
      form: {},
      payType: 1
    };
  },
  methods: {
    init() {
      this.visible = true;
    },
    async toPay() {
      const data = {
        appChannel: this.payType === 2 ? 22 : 21,
        orderCode: this.orderCode,
        payType: this.payType,
        price: this.price,
        // returnUrl: `${window.location.origin}/result?orderCode=`
        returnUrl: `${window.location.origin}/writing?orderCode=`
      };
      const res = await http("/hcp/trade/h5/unifiedOrder", { method: "POST", data });
      this.visible = false;
      if (this.payType === 1) {
        window.open(res.sign, "_blank");
      } else {
        sessionStorage.setItem("codeUrl", res.codeUrl);
        this.$router.push(
          `/scanTopay?price=${this.price}&orderCode=${this.orderCode}&orderType=addword`
        );
      }
    },
    async cancel() {
      const formData = new FormData();
      formData.append("orderCode", this.orderCode);
      await http("/hcp/trade/customer/cancelOrder", { method: "POST", data: formData });
      this.visible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.payAddword {
  .pay {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    border-radius: 2px;
    padding: 0 8px;
    border: 1px solid #cacdd6;
    margin-right: 10px;
    cursor: pointer;
    font-size: 12px;
    img {
      height: 14px;
      margin-top: -2px;
    }
    &.active {
      border: 1px solid #3377ff;
    }
  }
  .blue {
    color: #3377ff;
  }
  .red {
    color: #ff2342;
  }
}
</style>
